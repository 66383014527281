import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#34495E] w-full">
      <div className="container mx-auto px-4">
        <div className="py-4 flex flex-wrap justify-between items-center">
          <div className="flex items-center flex-shrink-0 text-white mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2"><path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" /></svg>
            <h1 className="font-semibold text-lg tracking-tight">Rental Map</h1>
          </div>
          <div>
            <ul className="flex-col sm:flex sm:flex-row">
              <li className="mt-2 mb-1 mr-2 text-white text-sm hover:underline">
                <a href='https://aka-shin.com/inquiry/' target='_blank'>お問い合わせ</a>
              </li>
              <li className="mt-2 mb-1 mr-2 text-white text-sm hover:underline">
                <Link to='/terms'>利用規約</Link>
              </li>
              <li className="mt-2 mb-1 mr-4 text-white text-sm hover:underline">
                <Link to='/policies'>プライバシーポリシー</Link>
              </li>
              <li className="mt-2 mb-1 text-white text-sm">
                Copyright © 2023 Shintaro Akao
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
