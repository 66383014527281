import React from 'react';
import RentalServiceList from './RentalServiceList';

const Top: React.FC = () => {
  return (
    <RentalServiceList />
  );
};

export default Top;
