import React, { SyntheticEvent } from 'react';
import ServiceProps from './ServiceProps';

const ServiceCard: React.FC<ServiceProps> = ({
  image,
  serviceName,
  serviceUrl,
  description,
}) => {
  const defaultImagePath = `${process.env.PUBLIC_URL}/no-image.png`;

  const handleError = (event: SyntheticEvent<HTMLImageElement>) => {
    const target = event.target as HTMLImageElement;
    target.src = defaultImagePath;
    target.onerror = null; // エラーが繰り返し発生しないように、onErrorハンドラをリセット
  };

  return (
    <a href={serviceUrl} target="_blank" rel="noopener noreferrer">
      <div className="w-80 h-full rounded overflow-hidden shadow-lg hover:shadow-md">
        <img
            className="max-w-full h-auto mx-auto"
            src={image}
            alt={serviceName}
            onError={handleError}
        />
        <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">{serviceName}</div>
            <p className="text-gray-700 text-sm">{description}</p>
        </div>
      </div>
    </a>
  );
};

export default ServiceCard;
