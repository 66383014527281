declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

const isProduction = process.env.NODE_ENV === "production";

export const trackPageView = (url: string): void => {
  if (isProduction && window.gtag) {
    window.gtag("config", process.env.REACT_APP_GA4, {
      page_path: url,
    });
  }
};
