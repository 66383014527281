import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">プライバシーポリシー</h1>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Googleアナリティクスについて</h2>
        <p>
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
          この規約に関しての詳細は<a className='underline' href='https://marketingplatform.google.com/about/analytics/terms/jp/' target='_blank'>Googleアナリティクス利用規約</a>や<a className='underline' href='https://policies.google.com/technologies/ads?hl=ja' target='_blank'>Googleポリシーと規約</a>をご覧ください。
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
