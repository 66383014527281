import React from 'react';

const Terms: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">利用規約</h1>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">１．本規約の適用</h2>
        <p>
          本規約は「Rental Map」（以下，本サービス）が提供する全てのサービスにおいて利用する全ユーザーに適用されるものとします。
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">２．禁止事項</h2>
        <p>ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</p>
        <ol className="list-decimal list-inside mt-2">
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>
            本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
          </li>
          <li>
            運営チーム，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
          </li>
          <li>本サービスによって得られた情報を商業的に利用する行為</li>
          <li>運営チームのサービスの運営を妨害するおそれのある行為</li>
          <li>不正アクセスをし，またはこれを試みる行為</li>
          <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
          <li>不正な目的を持って本サービスを利用する行為</li>
          <li>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
          <li>他のユーザーに成りすます行為</li>
          <li>運営チームが許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</li>
          <li>運営チームのサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
          <li>その他，運営チームが不適切と判断する行為</li>
        </ol>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">
          ３．保証の否認および免責事項
        </h2>
        <p>
          運営チームは，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておらず本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。また，本サービスを利用する上で発生するユーザー間の紛争などにおいても当事者同士で解決するものとします
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">４．サービス内容の変更等</h2>
        <p>
          運営チームは，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとします
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">５．利用規約の変更</h2>
        <p>
          運営チームは，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとし，ユーザーは変更された利用規約に同意したものとみなされます
        </p>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">６．準拠法・裁判管轄</h2>
        <ol className="list-decimal list-inside">
          <li>本規約の解釈にあたっては，日本法を準拠法とします</li>
          <li>本サービスに関して紛争が生じた場合には，東京地方裁判所を専属的合意管轄とします</li>
        </ol>
      </section>
    </div>
  );
};

export default Terms;
