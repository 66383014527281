import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Top from "./components/Top";
import Terms from "./components/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Footer from "./components/Footer";
import { trackPageView } from "./components/Analytics";
import "./App.css";

const Main: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);

    // Cleanup function
    return () => {};
  }, [location]);

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <Routes>
        <Route path="/" element={<div className="flex-grow"><Top /></div>} />
        <Route path="/terms" element={<div className="flex-grow"><Terms /></div>} />
        <Route path="/policies" element={<div className="flex-grow"><PrivacyPolicy /></div>} />
      </Routes>
      <Footer />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Main />
    </Router>
  );
};

export default App;
