import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient'
import ServiceProps from './ServiceProps';
import ServiceCard from './ServiceCard';

const RentalServiceList: React.FC = () => {
  const [services, setServices] = useState([] as ServiceProps[]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(6);

  const handleSelect = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(1);
  }

  const categories = [
    { id: '', name: 'すべて' },
    { id: '1', name: '家具・家電' },
    { id: '2', name: '衣料品' },
    { id: '3', name: '車' },
    { id: '4', name: 'スポーツ用品' },
    { id: '5', name: 'イベント・パーティー用品' },
    { id: '6', name: '楽器' },
    { id: '7', name: 'キャンプ用品' },
    { id: '8', name: 'カメラ' },
  ];

  const filteredServices = services.filter((service) =>
    selectedCategory === '' || String(service.categoryId) === selectedCategory
  );

  const pageCount = Math.ceil(filteredServices.length / perPage);

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    async function fetchServices() {
      const { data, error } = await supabase
        .from('rental_services')
        .select();

      if (error) {
        console.log(error);
      } else {
        const services = await Promise.all(data.map(async (service: any) => {
          const { data } = supabase.storage.from('rental-map').getPublicUrl(service.image);
          return {
            id: service.id,
            image: data.publicUrl,
            serviceName: service.service_name,
            serviceUrl: service.service_url,
            description: service.description,
            categoryId: service.category_id,
          };
        }));
        setServices(services);
      }
    }

    fetchServices();
  }, []);

  // ページネーションのためのスライスを実施する
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const servicesToShow = filteredServices.slice(startIndex, endIndex);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const maxPageNumbersToShow = 3;
  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  let endPage = Math.min(pageCount, startPage + maxPageNumbersToShow - 1);
  if (endPage - startPage + 1 < maxPageNumbersToShow) {
    startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
  }

  return (
    <div className='mt-10 mb-20'>
      <div className="flex flex-col items-center justify-center">
        <label htmlFor="my-select" className="font-bold mb-2">
          カテゴリ
        </label>
        <div className="relative">
          <select
            id="my-select"
            value={selectedCategory}
            onChange={handleSelect}
            className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M15.293 6.707a1 1 0 010 1.414L10.414 12l4.879 4.879a1 1 0 01-1.414 1.414L9 13.414l-4.879 4.879a1 1 0 01-1.414-1.414L7.586 12 2.707 7.121a1 1 0 011.414-1.414L9 10.586l4.879-4.879a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="mt-10 container lg:max-w-screen-lg md:max-w-screen-md mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 justify-items-center gap-8">
        {servicesToShow.map((service) => (
          <ServiceCard key={service.id} {...service} />
        ))}
      </div>
      <div className="flex justify-center mt-10 space-x-2">
        <div className="hidden sm:flex">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageClick(1)}
            className="px-4 py-1 font-bold rounded-md bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            最初
          </button>
        </div>
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageClick(currentPage - 1)}
          className="px-4 py-1 font-bold rounded-md bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          前
        </button>
        {startPage > 1 && (
          <span className="font-bold text-gray-700">...</span>
        )}
        {Array.from({ length: endPage - startPage + 1 }).map((_, index) => (
          <button
            key={index}
            className={`mx-1 font-bold rounded-full w-8 h-8 flex items-center justify-center ${
              currentPage === startPage + index ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => handlePageClick(startPage + index)}
          >
            {startPage + index}
          </button>
        ))}
        {endPage < pageCount && (
          <span className="font-bold text-gray-700">...</span>
        )}
        <button
          disabled={currentPage === pageCount}
          onClick={() => handlePageClick(currentPage + 1)}
          className="px-4 py-1 font-bold rounded-md bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          次
        </button>
        <div className="hidden sm:flex">
          <button
            disabled={currentPage === pageCount}
            onClick={() => handlePageClick(pageCount)}
            className="px-4 py-1 font-bold rounded-md bg-gray-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            最後
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentalServiceList;
